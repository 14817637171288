import { L1_CHAIN_IDS, SupportedChainId } from '../constants/chains'

export function constructSameAddressMap<T extends string>(
  address: T,
  additionalNetworks: SupportedChainId[] = []
): { [chainId: number]: T } {
  return (L1_CHAIN_IDS as readonly SupportedChainId[])
    .concat(additionalNetworks)
    .reduce<{ [chainId: number]: T }>((memo, chainId) => {
      memo[chainId] = address
      return memo
    }, {})
}

export function constructSameAddressMap2<T extends string>(
  address: T,
  additionalNetworks: SupportedChainId[] = [],
  l2Address: any = undefined,
  l2ChainId: any = undefined
): { [chainId: number]: T } {
  return (L1_CHAIN_IDS as readonly SupportedChainId[])
    .concat(additionalNetworks)
    .reduce<{ [chainId: number]: T }>((memo, chainId) => {
      if (chainId === l2ChainId) {
        // @ts-ignore
        memo[chainId] = l2Address
      } else {
        memo[chainId] = address
      }
      return memo
    }, {})
}
