import { useRef, useState } from 'react'
import { ArrowUpRight } from 'react-feather'
import styled, { css } from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { ExternalLink } from '../../theme'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

export enum FlyoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span<{ flyoutAlignment?: FlyoutAlignment }>`
  min-width: 196px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 3rem;
  z-index: 100;

  ${({ flyoutAlignment = FlyoutAlignment.RIGHT }) =>
    flyoutAlignment === FlyoutAlignment.RIGHT
      ? css`
          right: 0rem;
        `
      : css`
          left: 0rem;
        `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: 3rem;
    top: unset;
    right: 0;
    left: unset;
  `};
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

export default function ChangeETHWMenu() {
  const node = useRef<HTMLDivElement>()
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <span onClick={toggle}>
        <Trans>Get ETHW</Trans>
      </span>
      {open &&
        (() => {
          return (
            <MenuFlyout>
              <MenuItem href="https://changenow.io/?from=eth&to=ethw">
                <div>
                  <Trans>ChangeNOW</Trans>
                </div>
                <ArrowUpRight opacity={0.6} size={16} />
              </MenuItem>
              <MenuItem href="https://www.allchainbridge.com/#/">
                <div>
                  <Trans>SWFT</Trans>
                </div>
                <ArrowUpRight opacity={0.6} size={16} />
              </MenuItem>
              <MenuItem href="https://app.rocketx.exchange/swap/ETHEREUM.ethereum/ETHPOW.ethereum-pow-iou/1">
                <div>
                  <Trans>RocketX</Trans>
                </div>
                <ArrowUpRight opacity={0.6} size={16} />
              </MenuItem>
            </MenuFlyout>
          )
        })()}
    </StyledMenu>
  )
}
